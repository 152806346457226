import axios from "axios";
import successHandler from "./successHandler";
import errorHandler from "./errorHandler";
import { API_BASE_URL } from "../config/serverAPIConfig";

axios.defaults.baseURL = API_BASE_URL;
axios.defaults.withCredentials = true;

const request = {
  findByPeople: async ({ entity, id }) => {
    try {
      const response = await axios.get(entity + "/find/" + id);
      const { status, data } = response;
      successHandler(
        { data, status },
        { notifyOnFailed: true, notifyOnSuccess: false }
      );
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  create: async ({ entity, jsonData }) => {
    try {
      const response = await axios.post(entity + "/create", jsonData);
      const { status, data } = response;
      successHandler(
        { data, status },
        { notifyOnFailed: true, notifyOnSuccess: true }
      );
      return response.data;
    } catch (err) {
      return errorHandler(err);
    }
  },

  read: async ({ entity, id }) => {
    try {
      const response = await axios.get(entity + "/read/" + id);
      const { status, data } = response;
      successHandler(
        { data, status },
        { notifyOnFailed: true, notifyOnSuccess: false }
      );
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  patch: async ({ entity, jsonData }) => {
    try {
      const response = await axios.patch(entity, jsonData);
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  update: async ({ entity, id, jsonData }) => {
    try {
      const response = await axios.patch(entity + "/update/" + id, jsonData);
      const { status, data } = response;
      console.log(response);
      successHandler(
        { data, status },
        { notifyOnFailed: true, notifyOnSuccess: true }
      );
      return response.data;
    } catch (error) {
      console.log(error);
      return errorHandler(error);
    }
  },
  updateAndUpload: async ({ entity, id, jsonData }) => {
    try {
      const response = await axios.patch(entity + "/update/" + id, jsonData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
  delete: async ({ entity, id }) => {
    try {
      const response = await axios.delete(entity + "/delete/" + id);
      const { status, data } = response;
      successHandler(
        { data, status },
        { notifyOnFailed: true, notifyOnSuccess: true }
      );
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  filter: async ({ entity, options = {} }) => {
    try {
      let filter = options.filter ? "filter=" + options.filter : "";
      let equal = options.equal ? "&equal=" + options.equal : "";
      let query = `?${filter}${equal}`;
      const response = await axios.get(entity + "/filter" + query);
      const { status, data } = response;
      successHandler(
        { data, status },
        { notifyOnFailed: true, notifyOnSuccess: true }
      );
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  search: async ({ entity, options = {} }) => {
    try {
      let query = "?";
      for (let key in options) {
        query += `${key}=${options[key]}&`;
      }
      query = query.slice(0, -1);
      const response = await axios.get(entity + "/search" + query);
      const { status, data } = response;
      successHandler(
        { data, status },
        { notifyOnFailed: false, notifyOnSuccess: false }
      );
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  pagination: async ({ entity, options = {} }) => {
    try {
      let query = "?";
      for (let key in options) {
        query += key + "=" + options[key] + "&";
      }
      query = query.slice(0, -1);
      const response = await axios.get(entity + "/list" + query);
      const { status, data } = response;
      successHandler(
        { data, status },
        { notifyOnFailed: false, notifyOnSuccess: false }
      );
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  getAll: async ({ entity }) => {
    try {
      const response = await axios.get(entity + "/get-all");
      const { status, data } = response;
      successHandler(
        { data, status },
        { notifyOnFailed: false, notifyOnSuccess: false }
      );
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  summary: async ({ entity, options = {} }) => {
    try {
      let query = "?";
      for (var key in options) {
        query += key + "=" + options[key] + "&";
      }

      query = query.slice(0, -1);
      const response = await axios.get(entity + "/summary" + query);

      successHandler(response, {
        notifyOnSuccess: false,
        notifyOnFailed: false,
      });

      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },

  mail: async ({ entity, jsonData }) => {
    try {
      const response = await axios.post(entity + "/mail", jsonData);
      successHandler(response, {
        notifyOnSuccess: true,
        notifyOnFailed: true,
      });
      return response.data;
    } catch (error) {
      return errorHandler(error);
    }
  },
};

export default request;
